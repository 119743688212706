.calendar
{
	border:1px solid var(--gray-color);	
	user-select: none;
}
.calendar_top
{
	background:white;
	padding:10px 15px;
	font-size:1.5rem;
	font-weight:800;
	align-items: center;;
	gap:15px !important;
} 
.calendar_arrow 
{
	display:flex;
}
.calendar_arrow svg
{
	fill:var(--dark-color);
	width:24px;
	height:24px;
}
.calendar table
{
	text-align:center;
	border-collapse: collapse;
	width:100%;
}
.calendar th 
{
	background:var(--gray-color);
	color:var(--light-color);
	border:1px solid var(--light-color);
	font-weight: 400;
	padding:5px 10px;
	afont-size:0.9rem;
}
.calendar td 
{
	background:var(--disabled-color);
}
.calendar td .wrapper
{
	position:relative;
	width: 100%;
	height: 0;
	padding-top: 85%;
}

.calendar td.available 
{
	background:var(--light-color);
}
.calendar td.selected 
{
	background:var(--highlight-color) !important;
}
.calendar td.today a
{
	font-weight: 900;
	color:var(--primary-color) !important;
}
.calendar td a
{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	display:flex;
	align-items: center;
	justify-content: center;
	color:var(--dark-color);
	padding:10px;
}
.calendar td:hover 
{
	
}
.calendar td.dated 
{
	background:var(--visual-color);
}
