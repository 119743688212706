.calendar {
  border: 1px solid var(--gray-color);
  -webkit-user-select: none;
  user-select: none;
}

.calendar_top {
  background: #fff;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.5rem;
  font-weight: 800;
  gap: 15px !important;
}

.calendar_arrow {
  display: flex;
}

.calendar_arrow svg {
  fill: var(--dark-color);
  width: 24px;
  height: 24px;
}

.calendar table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
}

.calendar th {
  background: var(--gray-color);
  color: var(--light-color);
  border: 1px solid var(--light-color);
  afont-size: .9rem;
  padding: 5px 10px;
  font-weight: 400;
}

.calendar td {
  background: var(--disabled-color);
}

.calendar td .wrapper {
  width: 100%;
  height: 0;
  padding-top: 85%;
  position: relative;
}

.calendar td.available {
  background: var(--light-color);
}

.calendar td.selected {
  background: var(--highlight-color) !important;
}

.calendar td.today a {
  font-weight: 900;
  color: var(--primary-color) !important;
}

.calendar td a {
  width: 100%;
  height: 100%;
  color: var(--dark-color);
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.calendar td.dated {
  background: var(--visual-color);
}
/*# sourceMappingURL=path0.5dadace5.css.map */
